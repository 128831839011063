<template>
  <div v-if="!loaderActiveForLogout" :style="styleObject" class="page-header align-items-start min-vh-100" tabindex="-1">
    <span class="mask bg-white"></span>

    <div class="container">
      <div class="row">
        <div class="notifications">
          <material-alert
            v-if="error"
            color="danger"
            icon="ni ni-like-2 ni-lg"
            dismissible
          >
            {{ error }}
          </material-alert>
        </div>
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <!-- <div v-if="isLoginNormal" class="d-flex justify-content-center mt-6"> -->
          <div class="d-flex justify-content-center mt-6">
            <img
              v-if="this.$store.state.merchantLogo"
              style="max-width: 150px"
              :src="this.$store.state.merchantLogo"
              class="avatar-md mb-3 border-radius-lg"
              alt="user1"
            />
            <img
              v-else
              style="max-width: 100px"
              src="../assets/img/logo.png"
              class="avatar-md mb-3 border-radius-lg"
              alt="user1"
            />
          </div>
          <div class="card-body">
            <div v-if="isLoginNormal" class="mt-7 mb-3">
              <div
                class="text-center pb-0 fw-500"
                :style="`color: ${this.$store.state.labelColor} !important`"
              >
                Please input your phone number
              </div>
              <div
                class="text-center pb-0 fw-500"
                :style="`color: ${this.$store.state.labelColor} !important`"
              >
                to login/sign up
              </div>
              <div class="d-flex my-5" style="padding-left: 0px;">
                <div class="row" style="width: 100%;margin-left: 0px;margin-right: 0px;">
                  <div class="col-3 text-center">
                    <!--<material-input
                      id="phone_code"
                      type="text"
                      extraClass="border-bottom-green text-center"
                      disabled
                      name="phone_code"
                      v-model="phone_code"
                    />-->
                    <div class="form-control p-0 mt-1 pb-2 no-bg" id="form-control">
                        <select :disabled="checkAllowForeignerPhoneNumber == 'false' || checkAllowForeignerPhoneNumber == false" id="country_id" class="form-select p-2" v-model="form1.phone_code" 
                          :style="`font-size:1.1rem;border: 0px;border-bottom: 2.1px solid ${this.$store.state.labelColor};border-radius: 0px;padding: 0px !important;`"
                        >
                            <option v-for="(value, key) in countryData" :key="key" :value="value.value">
                              {{value.label}}
                            </option>
                        </select>
                    </div>
                  </div>
                  <div class="col-9" style="padding-right: 0px;">
                    <material-input
                      id="phone_number"
                      type="tel"
                      v-model="form1.phone_number"
                      extraClass="border-bottom-green"
                      name="phone_number"
                      @change="validatePhoneNumber( $event )"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mb-4">
                <material-button
                  class="mb-4 w-50 bg-green"
                  @click="validatePhone()"
                  :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
                  >OK
                </material-button>
                <div class="text-center mb-4 text-decoration-underline text-info" @click="continueAsGuest()" v-if="branch_code != ''" style="cursor: pointer;">
                  CONTINUE AS GUEST?
                </div>
              </div>
              <SocialLogin v-if="checkAllowMemberToSignupFromMoo"/>
            </div>
            <div v-if="isAlreadyPasscode && !isForgetPassword && !isShowEnterPasscodeYourBirtday && !isShowEnterOneTimePasscode" class="mb-2 mt-5">
              <div
                class="text-center text-green fw-500"
                :style="`color: ${this.$store.state.labelColor} !important'`"
              >
                <span>Enter your passcode</span>
              </div>
              <div
                id="enterPasscode"
                class="inputs d-flex flex-row justify-content-center mt-5"
              >
                <v-otp-input
                  ref="inputEnterPasscode"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="4"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  :conditionalClass="['one', 'two', 'three', 'four']"
                  :placeholder="['', '', '', '']"
                  @on-change="handleOnChange"
                  @on-complete="handleOnCompleteAlreadyPasscode"
                  v-model="form2.passcode"
                />
              </div>
              <div class="text-center mt-5">
                <a
                  class="text-decoration-underline text-info"
                  @click="handleEnbaleOptionForgotPasscode()"
                  >FORGET PASSCODE</a
                >
              </div>
            </div>
            <div v-if="isPasscode" class="mb-2 mt-5">
              <div
                class="text-center text-green fw-500"
                :style="`color: ${this.$store.state.labelColor} !important'`"
              >
                <span>Create a 4-digit passcode</span
                ><br />
                <span>You will be asked for this passcode in every login.</span>
              </div>
              <div
                id="enterCreatePasscode"
                class="inputs d-flex flex-row justify-content-center mt-5"
              >
                <v-otp-input
                  ref="inputCreatePasscode"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="4"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  :conditionalClass="['one', 'two', 'three', 'four']"
                  :placeholder="['', '', '', '']"
                  @on-change="handleOnChange"
                  @on-complete="handleOnCompletePasscode"
                  v-model="form2.passcode"
                />
              </div>
            </div>
            <div v-if="isConfirmPasscode" class="mb-2 mt-5">
              <div
                class="text-center text-green fw-500"
                :style="`color: ${this.$store.state.labelColor} !important'`"
              >
                <span>Confirm your passcode</span
                ><br />
                <span>Input your passcode once again</span>
              </div>
              <div
                id="inputConfirmPasscode"
                class="inputs d-flex flex-row justify-content-center mt-5"
              >
                <v-otp-input
                  ref="inputConfirmPasscode"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="4"
                  :should-auto-focus="false"
                  :is-input-num="true"
                  :conditionalClass="['one', 'two', 'three', 'four']"
                  :placeholder="['', '', '', '']"
                  @on-change="handleOnChange"
                  @on-complete="handleOnCompleteConfirmPasscode"
                  v-model="form2.confirmPasscode"
                />
              </div>
            </div>
            <div v-if="isOtp" class="mb-2 mt-5">
              <div
                class="text-left text-green fw-500"
                :style="`color: ${this.$store.state.labelColor} !important`"
              >
                <span>An 4 digit OTP has been sent to your phone number.</span
                ><br />
                <span>Enter below:</span>
              </div>
              <div
                id="otp"
                class="inputs d-flex flex-row justify-content-center mt-10"
              >
                <v-otp-input
                  ref="inputOTP"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="4"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  :conditionalClass="['one', 'two', 'three', 'four']"
                  :placeholder="['', '', '', '']"
                  @on-change="handleOnChange"
                  @on-complete="handleOnComplete"
                  v-model="form2.otp"
                />
              </div>
              <div
                class="text-center color-common my-2"
                v-html="countDownText"
                :style="`color: ${this.$store.state.labelColor} !important`"
              ></div>
              <div class="text-center my-1">
                <a
                  class="text-decoration-underline text-info"
                  @click="sendOTP(true)"
                  >Resend OTP</a
                >
              </div>
            </div>
            <div v-if="isAlreadyPasscode && isForgetPassword" class="mb-2 mt-5">
              <div
                class="text-center text-green fw-500"
                :style="`color: ${this.$store.state.labelColor} !important'`"
              >
                <span>Reset your passcode</span>
              </div>
              <div style="position: absolute;bottom: 0;left: 30%;transform: translateX(-20%);">
                <material-button v-if="!isEnableGetOneTimePasscode && (checkAllowResetPasscodeToOneTimeOtp == 'true' || checkAllowResetPasscodeToOneTimeOtp == true)" :disabled="isEnableGetOneTimePasscode || (checkAllowResetPasscodeToOneTimeOtp == 'false' || checkAllowResetPasscodeToOneTimeOtp == false)" class="mb-2 bg-color-common" @click="handleGetOneTimePasscode()" :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;width:100%`">
                    Get one-time passcode
                </material-button>
                <div class="text-center mb-2">
                <a v-if="isEnableGetOneTimePasscode || (checkAllowResetPasscodeToOneTimeOtp == 'false' || checkAllowResetPasscodeToOneTimeOtp == false)" class=""></a>
                <a v-else class="">OR</a>
                </div>
                <material-button :disabled="checkAllowResetPasscodeToBirthday == 'false' || checkAllowResetPasscodeToBirthday == false" class="mb-2 bg-color-common" @click="gotoResetToInitialPasscode()" :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;width:100%`">
                    Reset to initial passcode DDMM*
                </material-button>
                <div class="mb-1">
                    <a class="" style="margin-left: 25px;">*DD: day of your birthday</a>
                </div>
                <div class="mb-4">
                    <a class="" style="margin-left: 25px;">MM: month of your birthday</a>
                </div>
              </div>
            </div>
            <div v-if="isAlreadyPasscode && isShowEnterPasscodeYourBirtday">
              <div class="mt-6 mb-3">
                  <form role="form" class="text-start">
                      <div class="text-center pb-3">
                      Enter your birthday in DDMM* format
                      </div>
                      <div id="otp" class="inputs d-flex flex-row justify-content-center mt-6" style="align-items: center;">
                      <span style="margin-top: 10px;" class="material-icons me-3 icon-color-green md-30">lock_open</span>
                        <v-otp-input
                          ref="inputOTP"
                          input-classes="otp-input"
                          separator=""
                          :num-inputs="4"
                          :should-auto-focus="true"
                          :is-input-num="true"
                          :conditionalClass="['one', 'two', 'three', 'four']"
                          :placeholder="['', '', '', '']"
                          @on-change="handleOnChange"
                          @on-complete="handleOnCompleteAlreadyPasscode"
                          v-model="form2.passcode"
                        />
                      </div>
                  </form>
                  <div style="display: inline-flex;gap: 10px;justify-content: center;align-items: center;width: 100%;" class="mt-5">
                      <div class="text-center">
                          <a style="font-size: 12px;" class="">Forget your birthday ?</a>
                      </div>
                      <div class="text-center">
                          <a style="font-size: 12px;" @click="handleGetOneTimePasscode()" class="text-decoration-underline text-info">Get one-time passcode</a>
                      </div>
                  </div>
              </div>
              <div style="position: absolute;bottom: 0;left: 30%;transform: translateX(-20%);">
                  <div class="mb-1">
                      <a class="" style="margin-left: 25px;">*DD: day of your birthday</a>
                  </div>
                  <div class="mb-4">
                      <a class="" style="margin-left: 25px;">MM: month of your birthday</a>
                  </div>
              </div>
            </div>
            <div v-if="isAlreadyPasscode && isShowEnterOneTimePasscode">
              <div class="mt-6 mb-3">
                  <form role="form" class="text-start">
                      <div class="text-center pb-3">
                      Enter one-time passcode to login
                      </div>
                      <div id="otp" class="inputs d-flex flex-row justify-content-center mt-6" style="align-items: center;">
                      <span style="margin-top: 10px;" class="material-icons me-3 icon-color-green md-30">lock_open</span>
                        <v-otp-input
                          ref="inputOTP"
                          input-classes="otp-input"
                          separator=""
                          :num-inputs="4"
                          :should-auto-focus="true"
                          :is-input-num="true"
                          :conditionalClass="['one', 'two', 'three', 'four']"
                          :placeholder="['', '', '', '']"
                          @on-change="handleOnChange"
                          @on-complete="handleOnCompleteAlreadyPasscode"
                          v-model="form2.passcode"
                        />
                      </div>
                  </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2" style="z-index: 10001 !important;">
      <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
                :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
                :close-handler="closeSnackbar" />
      <!-- <material-snackbar
        v-if="snackbar === 'danger'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        color="danger"
        :close-handler="closeSnackbar"
      /> -->
    </div>
    <material-loading :active="loaderActive" />
    <TermService :iAgree="form1.i_agree" :phone="form1.phone_number" :changeCheckboxAgree="changeCheckboxAgree" :agree="agree" />
    <ModalSuccess :processAcction="closeModal" :success="false"
      message="Member not found<br>Please approach our staff for membership" buttonText="Ok" />
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import { mapMutations } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import VOtpInput from "vue3-otp-input";
import SocialLogin from "@/components/SocialLogin";
import router from "@/router";
import TermService from "@/components/TermService.vue";
import AuthService from "../services/auth.service";
import { useRoute } from 'vue-router'
import { ref } from "vue";
import $ from "jquery";
import TokenService from "../services/token.service";
import MemberService from "../services/member.service";
import CryptoJS from 'crypto-js';
import axios from "axios";
import { backToOnlineOrdering, localStorageName } from '@/config/utils.js';
import ModalSuccess from "@/components/ModalSuccess.vue";

export default {
  name: "sign-in",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialAlert,
    MaterialSnackbar,
    VOtpInput,
    SocialLogin,
    MaterialLoading,
    TermService,
    ModalSuccess
  },
  setup() {
    const inputOTP = ref(null);
    const inputEnterPasscode = ref(null);
    const inputCreatePasscode = ref(null);
    const inputConfirmPasscode = ref(null);

    const clearInputOTP = () => {
      inputOTP.value.clearInput();
    };
    const clearInputEnterPasscode = () => {
      inputEnterPasscode.value.clearInput();
    };
    const clearInputCreatePasscode = () => {
      inputCreatePasscode.value.clearInput();
    };
    const clearInputConfirmPasscode = () => {
      inputConfirmPasscode.value.clearInput();
    };
    return { inputOTP, inputEnterPasscode, inputCreatePasscode, inputConfirmPasscode, clearInputOTP, clearInputEnterPasscode, clearInputCreatePasscode, clearInputConfirmPasscode };
  },
  data() {
    return {
      phone_code: "65",
      form1: {
        phone_number: "",
        invite_code: "",
        i_agree: false,
        phone_code: "65"
      },
      message: "",
      form2: {
        otp: "",
        phone_number: "",
        i_agree: true,
        passcode: "",
        confirmPasscode: "",
        is_empty_passcode: false,
        phone_code: ""
      },
      snackbar: null,
      error: "",
      isLoginNormal: true,
      isPasscode: false,
      isConfirmPasscode: false,
      isAlreadyPasscode: false,
      isOtp: false,
      timerEnabled: true,
      countDown: 120,
      countDownText: "120s",
      timeout: "",
      timer_on: 0,
      merchantCode: "",
      loaderActive: false,
      isFirstLogin: false,
      branch_code: "",
      isForgetPassword: false,
      isShowEnterPasscodeYourBirtday: false,
      isShowEnterOneTimePasscode: false,
      checkAllowResetPasscodeToBirthday: null,
      checkAllowResetPasscodeToOneTimeOtp: null,
      isEnableGetOneTimePasscode: true,
      loaderActiveForLogout: false,
      countryData: [],
      checkAllowForeignerPhoneNumber: null,
      checkAllowMemberToSignupFromMoo: true
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.loaderActiveForLogout = false;
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  created() {
    const branch_code = this.$route.query.branch_code ?? '';
    if (branch_code) {
      const isAllowMemberToSignupFromMoo = localStorage.getItem(localStorageName("ALLOW_MEMBER_TO_SIGNUP_FROM_MOO"), 'true');
      this.checkAllowMemberToSignupFromMoo = isAllowMemberToSignupFromMoo == 'false' ? false : true;
    }
    this.getCountriesForDropdown();
    const token = TokenService.getLocalAccessToken();
    const isLogout = this.$route.query.isLogout ?? '';
    const code = this.$router.currentRoute.value.params.merchant_code ?? "";
    const tableCode = this.$route.query.table_code ?? "";
    // localStorage.setItem("MERCHANT_CODE", code);
    var merchantCode = code ? `/${code}` : "";

    if(isLogout != "" && token) {
      this.loaderActiveForLogout = true;
      TokenService.removeUser();
      var url = process.env.VUE_APP_ONLINE_ORDERING_BY_BRANCH_URL+merchantCode;

      if (tableCode) {
        url += "/QR/" + isLogout + "/" + tableCode + "/order";
      } else {
        url += "/" + isLogout + "/order";
      }
      
      window.open(url, "_self");
    }
  },
  mounted() {
    const isAllowResetPasscodeToBirthday = localStorage.getItem(localStorageName("ALLOW_RESET_PASSCODE_TO_BIRTHDAY"), 'false');
    const isAllowResetPasscodeToOneTimeOtp = localStorage.getItem(localStorageName("ALLOW_RESET_PASSCODE_TO_ONE_TIME_OTP"), 'false');
    const isAllowForeignerPhoneNumber = localStorage.getItem(localStorageName("ALLOW_FOREIGNER_PHONE_NUMBER"), 'false');
    
    this.checkAllowResetPasscodeToBirthday = isAllowResetPasscodeToBirthday;
    this.checkAllowResetPasscodeToOneTimeOtp = isAllowResetPasscodeToOneTimeOtp;
    this.checkAllowForeignerPhoneNumber = isAllowForeignerPhoneNumber;
    this.form1.invite_code =
      this.$router.currentRoute.value.params.invite_code ?? "";
    const code = this.$router.currentRoute.value.params.merchant_code ?? "";
    // localStorage.setItem("MERCHANT_CODE", code);
    this.merchantCode = code ? `/${code}` : "";
    const token = TokenService.getLocalAccessToken();
    const branch_code = this.$route.query.branch_code ?? '';
    // const isLogout = this.$route.query.isLogout ?? '';
    const table_code = this.$route.query.table_code ?? '';
    const from = this.$route.query.from ?? '';
    // if(isLogout != "" && token) {
    //   TokenService.removeUser();
    //   window.open(process.env.VUE_APP_ONLINE_ORDERING_BY_BRANCH_URL+this.merchantCode+"/"+isLogout+"/order","_self"); 
    // }
    if(branch_code != "") {
        this.branch_code = branch_code;
    }
    if (token && this.merchantCode) {
      if(branch_code != "") {
        AuthService.checkTokenHasExpired().then(
          (response) => {
            if (response.data.result.isSuccess) {
              var memberLogin = TokenService.getMember();
              backToOnlineOrdering(this.merchantCode, branch_code, token, memberLogin?.data.user.id, table_code, from);
              // window.open(process.env.VUE_APP_ONLINE_ORDERING_BY_BRANCH_URL+this.merchantCode+"/"+branch_code+"?key="+token+"&memberId="+ memberLogin?.data.user.id+"&from="+ from, '_self'); 
            }
          },
          (error) => {
            console.log(error);
          }
        )
      } else {
        router.push(this.merchantCode + "/home");
      }
    }

    const route = useRoute()

    if (route.query.phone) {
      this.form1.phone_number = route.query.phone;
      $('.bottom_modal_terms').click();
    }
  },
  computed: {
    styleObject() {
      return {
        "--label-color": this.$store.state.labelColor,
      };
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    validatePhone: async function () {
      this.closeSnackbar()
      this.showLoader()
      var merchantId = localStorage.getItem(localStorageName('MERCHANT_ID'), 0);
      var merchantCode = this.$route.params.merchant_code ?? '';

      if (merchantId == 0 && merchantCode != '') {
        await MemberService.getMerchantId({
          merchant_code: merchantCode
        })
        .then(
          (response) => {
            if (response.data.result.isSuccess) {
              merchantId = response.data.data.merchantId;
              axios.defaults.headers.common['MerchantId'] = merchantId;
              localStorage.setItem(localStorageName('MERCHANT_ID'), merchantId);
            } else {
              this.snackbar = 'danger'
              this.message = response.data.result.message
              this.setTimeOutNotification()
            }
          },
          (error) => {
            this.snackbar = 'danger'
            this.message = error
            this.hideLoader()
          }
        )
      }

      if (merchantId) {
        await AuthService.validatePhoneNumber(this.form1.phone_number, this.form1.phone_code).then(
          (response) => {
            if (response.data.result.isSuccess) {
              if(response.data.data.is_first_login && response.data.data.is_empty_passcode) {
                // first time login
                if (this.checkAllowMemberToSignupFromMoo) {
                  $(".bottom_modal_terms").click();
                  this.isFirstLogin = true
                  this.form2.is_empty_passcode = response.data.data.is_empty_passcode;
                } else {
                  $('.btn-success-modal').click();
                }
              } else {
                this.isLoginNormal = false;
                this.isFirstLogin = false
                this.form1.i_agree = true
                this.isOtp = false;
                if(!response.data.data.is_empty_passcode) {
                  this.isAlreadyPasscode = true;
                  this.isPasscode = false;
                  this.isConfirmPasscode = false;
                } else {
                  this.isAlreadyPasscode = false;
                  this.isPasscode = true;
                  this.isConfirmPasscode = true;
                }
                this.form2.phone_number = this.form1.phone_number;
                this.form2.is_empty_passcode = response.data.data.is_empty_passcode;
                // this.sendOTP()
              }
            } else {
              this.snackbar = 'danger'
              this.message = response.data.result.message
              this.setTimeOutNotification()
            }
            this.hideLoader()
          },
          (error) => {
            this.snackbar = 'danger'
            this.message = error
            this.hideLoader()
          }
        )
      }
    },
    changeCheckboxAgree() {
      this.form1.i_agree = !this.form1.i_agree
    },
    agree() {
      if(!this.form1.i_agree) {
        this.snackbar = 'danger'
        this.message = "Terms of service is required"
        this.setTimeOutNotification()
      } else {
        this.sendOTP()
      }
    },
    validatePhoneNumber() {
      this.form1.phone_number = this.form1.phone_number.replaceAll(' ', '');
      this.form1.phone_number = this.form1.phone_number.replaceAll('+65', '');
    },
    sendOTP: function (isResend = false) {
      var _this = this;
      _this.closeSnackbar();
      _this.stopCount();
      _this.$store.dispatch("auth/sendOtp", _this.form1).then(
        (data) => {
          $(".btn-close").click();
          if (data != undefined && !data.result.isSuccess) {
            _this.message = data.result.message;
            _this.snackbar = "danger";
          } else {
            if (isResend == true) {
              _this.message = data.result.message;
            }
            _this.form2.phone_number = _this.form1.phone_number;
            _this.isLoginNormal = false;
            var sending_otp_signup = localStorage.getItem(localStorageName("SENDING_OTP_SIGNUP"), 0);
            if(sending_otp_signup == 1 || sending_otp_signup == '1') {
              if(this.form1.phone_code == '65') {
                _this.isOtp = true;
                _this.countDown = 120;
                _this.startCount();
              } else {
                _this.isPasscode = true;
                _this.isConfirmPasscode = true;
              }
            } else {
              _this.isPasscode = true;
              _this.isConfirmPasscode = true;
            }
          }
          _this.setTimeOutNotification();
        },
        (error) => {
          console.log("ERROR" + error);
        }
      );
    },
    loginOtp: function () {
      var _this = this;
      AuthService.validateOtp({ phone_number: this.form2.phone_number, otp: this.form2.otp }).then(
        (response) => {
          if (response.result.isSuccess) {
            // console.log("response.data.data", response.data);
            // enter
            _this.isOtp = false;
            _this.isPasscode = true;
            _this.isConfirmPasscode = true;
          } else {
            this.snackbar = 'danger'
            this.message = response.result.message
            this.setTimeOutNotification();
            _this.clearInputOTP();
          }
          this.hideLoader()
        },
        (error) => {
          this.snackbar = 'danger'
          this.message = error
          this.hideLoader()
        }
      )
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    handleOnComplete(value) {
      this.form2.otp = value;
      this.loginOtp();
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    timedCount() {
      if (this.countDown > 0) {
        this.countDown -= 1;
        this.countDownText = `${this.countDown} s`;
        this.timeout = setTimeout(this.timedCount, 1000);
      }
    },
    startCount() {
      if (!this.timer_on) {
        this.timer_on = 1;
        this.timedCount();
      }
    },
    stopCount() {
      clearTimeout(this.timeout);
      this.countDown = 120;
      this.timer_on = 0;
    },
    callback: function (response) {
      console.log("Handle the response", response);
    },
    showSnackbarError() {
      this.snackbar = "danger";
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    handleOnCompletePasscode: function (value) {
      if(value != "") {
        this.form2.passcode = value;
        this.form2.confirmPasscode = "";
        this.form2.phone_code = this.form1.phone_code;
        setTimeout(() => {
          this.clearInputConfirmPasscode();
        }, 500);
      } else {
        this.form2.passcode = "";
        this.form2.confirmPasscode = "";
        setTimeout(() => {
          this.clearInputConfirmPasscode();
        }, 500);
      }
      this.$nextTick(() => {
        this.$refs.inputConfirmPasscode.$el.querySelector('input').focus();
      });
      // this.isPasscode = false;
      // this.isConfirmPasscode = true;
    },
    handleOnCompleteConfirmPasscode: function (value) {
      if(value === this.form2.passcode) {
        this.handleLogin();
      } else {
        this.snackbar = 'danger'
        this.message = "Passcodes do not match. Please try again!"
        this.setTimeOutNotification();
        setTimeout(() => {
          this.clearInputConfirmPasscode();
        }, 500);
      }
    },
    handleOnCompleteAlreadyPasscode: function (value) {
      this.form2.passcode = value;
      this.form2.phone_code = this.form1.phone_code;
      this.handleLogin();
    },
    handleLogin() {
      var _this = this;
      _this.closeSnackbar();
      _this.showLoader();
      _this.$store.dispatch("auth/loginOtp", this.form2).then(
        (data) => {
          if (data != undefined && !data.result.isSuccess) {
            this.hideLoader();
            _this.message = data.result.message;
            _this.showSnackbarError();
            // _this.clearInputOTP();
            setTimeout(() => {
              _this.clearInputEnterPasscode();
            }, 500);
            // _this.clearInputCreatePasscode();
            // _this.clearInputConfirmPasscode();
          } else {
            // save passcode using encrypt
            this.encryptData(this.form2.passcode);
            var memberLogin = TokenService.getMember();
            _this.isLoginNormal = false;
            if(_this.isFirstLogin) {
              const branch_code = this.$route.query.branch_code ?? '';
              const table_code = this.$route.query.table_code ?? '';
              const from = this.$route.query.from ?? '';

              if(branch_code != "") {
                const token = TokenService.getLocalAccessToken();
                if (token && this.merchantCode) {
                  backToOnlineOrdering(this.merchantCode, branch_code, token, memberLogin?.data.user.id, table_code, from);
                  // window.open(process.env.VUE_APP_ONLINE_ORDERING_BY_BRANCH_URL+this.merchantCode+"/"+branch_code+"?key="+token+"&memberId="+ memberLogin?.data.user.id+"&from="+ from, '_self');
                }
              } else {
                router.push(this.merchantCode + "/profile");
              }
            } else {
              const branch_code = this.$route.query.branch_code ?? '';
              const table_code = this.$route.query.table_code ?? '';
              const from = this.$route.query.from ?? '';

              if(branch_code != "") {
                const token = TokenService.getLocalAccessToken();
                if (token && this.merchantCode) {
                  backToOnlineOrdering(this.merchantCode, branch_code, token, memberLogin?.data.user.id, table_code, from);
                  // window.open(process.env.VUE_APP_ONLINE_ORDERING_BY_BRANCH_URL+this.merchantCode+"/"+branch_code+"?key="+token+"&memberId="+ memberLogin?.data.user.id+"&from="+ from, '_self');
                }
              } else {
                if(this.isShowEnterOneTimePasscode) {
                  router.push(this.merchantCode + "/home?memorize=1");
                } else {
                  router.push(this.merchantCode + "/home");
                }
              }
            }
          }
          _this.hideLoader();
        },
        (error) => {
          // _this.clearInputOTP();
          setTimeout(() => {
            _this.clearInputEnterPasscode();
          }, 500);
          // _this.clearInputCreatePasscode();
          // _this.clearInputConfirmPasscode();
          console.log("ERROR" + error);
        }
      );
    },
    handleEnbaleOptionForgotPasscode() {
      const code = this.$router.currentRoute.value.params.merchant_code ?? "";
      axios.get(process.env.VUE_APP_BASE_URL + '/member/get-merchant-setting',
        {
          headers: {
            "MerchantCode": code
          }
        }
      )
        .then((response) => {
          var dataSetting = response.data;
          if (dataSetting.result.isSuccess) {
            if(dataSetting.data && dataSetting.data.getSmsBalanceByAdminMerchant > 0) {
              this.isEnableGetOneTimePasscode = false;
            }
            this.isForgetPassword = true;
          }
        },
          (error) => {
            var status = error.response.status
            console.log(status)
          }
        );
    },
    handleForgotPassword() {
      this.closeSnackbar()
      this.showLoader()
      var link = window.location.origin+this.merchantCode+"/forget-passcode";
      AuthService.sendLinkForgotPasscode(this.form1.phone_number, link).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = 'success';
            this.message = "Sent your pass code via phone number!";
            this.setTimeOutNotification();
          } else {
            this.snackbar = 'danger'
            this.message = response.data.result.message
            this.setTimeOutNotification()
          }
          this.hideLoader()
        },
        (error) => {
          this.snackbar = 'danger'
          this.message = error
          this.hideLoader()
        }
      )
    },
    encryptData(passcode) {
      if (passcode) {
          const encryptedData = CryptoJS.AES.encrypt(passcode, process.env.VUE_APP_KEY_ENCRYPT_MEMBER_INFORMATION).toString();
          localStorage.setItem(localStorageName('MEMBER_PASSCODE'), encryptedData ?? '');
      }
    },
    continueAsGuest() {
      if(this.branch_code != "") {
        const table_code = this.$route.query.table_code ?? '';
        let from = '/order';

        var url = process.env.VUE_APP_ONLINE_ORDERING_BY_BRANCH_URL + this.merchantCode;

        if (table_code) {
          url += '/QR/' + this.branch_code + "/" + table_code;
        } else {
          url += "/" + this.branch_code;
        }

        url += from;

        window.open(url, '_self');
      }
    },
    gotoResetToInitialPasscode() {
      this.closeSnackbar()
      this.showLoader()
      AuthService.sendResetToInitialPasscode(this.form1.phone_number, this.form1.phone_code).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.isForgetPassword = false;
            this.isShowEnterPasscodeYourBirtday = true;
            // this.snackbar = 'success';
            // this.message = "Sent your pass code via phone number!";
            // this.setTimeOutNotification();
          } else {
            this.snackbar = 'danger'
            this.message = response.data.result.message
            this.setTimeOutNotification()
          }
          this.hideLoader()
        },
        (error) => {
          this.snackbar = 'danger'
          this.message = error
          this.hideLoader()
        }
      )
    },
    handleGetOneTimePasscode() {
      this.closeSnackbar()
      this.showLoader()
      AuthService.getOneTimePassCode(this.form1.phone_number, this.form1.phone_code).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.isForgetPassword = false;
            this.isShowEnterPasscodeYourBirtday = false;
            this.isShowEnterOneTimePasscode = true;
            this.snackbar = 'success';
            this.message = "Sent your pass code via phone number!";
            this.setTimeOutNotification();
          } else {
            this.snackbar = 'danger'
            this.message = response.data.result.message
            this.setTimeOutNotification()
          }
          this.hideLoader()
        },
        (error) => {
          this.snackbar = 'danger'
          this.message = error
          this.hideLoader()
        }
      )
    },
    getCountriesForDropdown() {
      this.isLoadData = false;
      this.showLoader();
      MemberService.getCountriesForDropdown().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.countryData = response.data.data;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    closeModal() {
      $(".btn-close").click()
    },
  },
};
</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border: none;
  border-bottom: 2px solid var(--label-color);
  text-align: center;
  border-radius: 0px !important;
  background: none;
}

.form-control:disabled,
.form-control[readonly] {
  padding: 2px !important;
}

.text-green {
  color: #ffbe30;
}

.otp-input:focus {
  outline: none !important;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}

#phone_number {
  padding: 0px !important;font-size: 1.1rem; margin-top: 6px;
}

#phone_code {
  padding: 0px !important;font-size: 1.1rem;
  cursor: not-allowed;
  background-image: linear-gradient(0deg, var(--label-color) 2.1px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #f0f2f5 1px, rgba(209, 209, 209, 0) 0) !important;
}
</style>
